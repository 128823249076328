<div class="modal-header">
  <h4 class="modal-title pull-left m-0">Modify Department</h4>
  <button type="button" class="close pull-right p-0 btn" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="bodyReady">
  <form [formGroup]="departmentForm" class="form-validate" (ngSubmit)="submitForm($event, departmentForm.value)"
    novalidate>

    <fieldset>
      <div class="form-group row">
        <label class="col-sm-4 control-label fw-bold mb-2">Department Name*</label>
        <div class="col-sm-8">
          <input class="form-control" type="text" formControlName="name" placeholder="Enter Department Name" />
          <span class="text-danger" *ngIf="
              departmentForm.controls['name'].hasError('required') &&
              (departmentForm.controls['name'].dirty || departmentForm.controls['name'].touched)
            ">
            This field is required
          </span>
          <span class="text-danger" *ngIf="
              !departmentForm.controls['name'].hasError('required') &&
              departmentForm.controls['name'].hasError('whitespace') &&
              (departmentForm.controls['name'].dirty || departmentForm.controls['name'].touched)
            ">
            Department name cannot be empty
          </span>
        </div>
      </div>
    </fieldset>


    <button class="pull-right btn btn-info" type="submit">Update</button>
    <div class="clearfix"></div>
    <hr>
       <fieldset>
      <div class="form-group">
        <label class="control-label fw-bold mb-2">Add Locations*</label>
        <ng-select [items]="availableLocations" bindLabel="name" bindValue="id" [multiple]="true"
          placeholder="Select Locations" formControlName="locations" notFoundText="No locations found">
        </ng-select>
        <span class="text-danger" *ngIf="hasError('locations', 'required')">
          At least one location must be selected.
        </span>
      </div>
    </fieldset>

    <button class="pull-right btn btn-info" type="button" (click)="addLocations()">Save Locations</button>
    <div class="clearfix"></div>
  </form>
  <hr>
  <h3>Locations</h3>
  <table class="table table-striped table-bordered table-hover">
    <thead>
      <tr>
        <th>#</th>
        <th>Location</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let location of department.locations; let i = index">
        <td>{{(i+1)}}</td>
        <td>{{location.name}}</td>
        <td>
          <button class="btn btn-sm btn-danger" (click)="detachDepartmentLocation(location.id, i)">
            <i class="fa fa-ban"></i> Detach Location
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
