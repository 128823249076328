import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {

  constructor(public api: ApiService) { }

  getRoles() {
    return this.api.get('/company/role');
  }

  create(body: Object) {
    return this.api.post('/company/user', body);
  }

  get(params: any) {
    return this.api.get('/company/user', params);
  }

  getVulnerabilityUnassignedUsers(id: any, company_id: any) {
    return this.api.get('/company/get-unassigned-users/' + id + '?company_id=' + company_id);
  }

  getById(id: Number) {
    return this.api.get('/company/user' + id);
  }

  update(body: Object, id: any) {
    return this.api.put('/company/user/' + id, body);
  }

  remove(user_id: any, company_id?: any) {
    return this.api.delete('/company/user/' + user_id + '?company_id=' + company_id);
  }

  setPassword(body: any) {
    return this.api.put('/company/auth/change-password', body);
  }

  getQRCode(token: any) {
    return this.api.get('/company/user/' + token + '/qrcode');
  }

  verifyCode(token: any, body: any) {
    return this.api.put('/company/user/' + token + '/verify', body);
  }

  assignVulnerability(body: any) {
    return this.api.post('/company/assign-vulnerability-to-company-user', body);
  }

  resolveVulnerability(body: any) {
    return this.api.post('/company/mark-vulnerability-as-resolved', body);
  }
}
