import { fade, fadeIn, fadeOut, rotateBackArrow } from './../../../core/animations/common-animations';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  animations: [
    fade,
    fadeIn,
    fadeOut,
    rotateBackArrow
  ]
})
export class SearchBoxComponent implements OnChanges {

  searchTextValue = '';

  /**
     * Input with the getter
     * Parent component will use this as [(searchText)]=''
     * that will bind the input variable two ways
     * @returns {string}
     */
  @Input() get searchText() {
    return this.searchTextValue;
  }

  /**
     * searchText change event emitter
     * when we emit to this it will update the text value in parent
     * @type {EventEmitter<any>}
     */
  @Output() searchTextChange = new EventEmitter();

  /**
     * This is for placeHolder text
     */
  @Input() placeHolderText!: string;

  /**
     * This is the output event on which parent can call filter function
     * @type {EventEmitter<any>}
     */
  @Output() onKeyup = new EventEmitter();

  textFocus = false;
  placeHolder = '';

  /**
     * Make copy of local placeholder on focus we can clear this and get it back later
     * @param changes
     */
  ngOnChanges(changes: any) {
    if (changes.placeHolderText && this.placeHolderText) {
      this.placeHolder = this.placeHolderText;
    }
  }

  /**
     * On key up of the input box emit the data to parent
     * So parent can filter based on the text changes
     */
  outText() {

    this.searchTextChange.emit(this.searchTextValue);
    this.onKeyup.emit();
  }

  /**
     * Toggle the focus and clear the placeholder and get it back
     */
  toggleFocus() {
    this.textFocus = !this.textFocus;
    if (this.textFocus) {
      this.placeHolder = '';
    } else {
      this.placeHolder = this.placeHolderText;
    }
  }

  /**
     * Clear the input field
     */
  clear() {
    this.searchTextValue = '';
    this.outText();
  }

  /**
     * Check if box is active - focused or has some value
     * @returns {boolean}
     */
  get boxActive() {
    return this.textFocus || (this.searchText && this.searchText.length);
  }


  /**
     * Setter for searchText
     * @param val
     */
  set searchText(val) {
    this.searchTextValue = val;
  }

}
