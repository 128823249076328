import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  logged_in_user_type = localStorage.getItem('account_type');
  api_prefix = '/admin/company';

  constructor(public api: ApiService) {
    if (this.logged_in_user_type === 'company_user') {
      this.api_prefix = '/company';
    }
  }

  getClients() {
    return this.api.get('/admin/clients/list');
  }

  create(body: Object) {
    return this.api.post('/admin/company', body);
  }

  get() {
    return this.api.get('/admin/company');
  }

  getById(id: Number) {
    return this.api.get('/admin/company/' + id);
  }

  update(body: Object, id: any) {
    return this.api.put('/admin/company/' + id, body);
  }

  delete(id: Number) {
    return this.api.delete('/admin/company/' + id);
  }

  addLocation(body: any) {
    return this.api.post(this.api_prefix + '/location', body);
  }

  getCompanyLocations(companyId: any) {
    return this.api.get(this.api_prefix + '/location/' + companyId);
  }

  getUserCompanyLocations(companyId: any) {
    return this.api.get('/company/location/' + companyId);
  }

  deleteLocation(location_id: Number) {
    return this.api.delete(this.api_prefix + '/location/' + location_id);
  }

  updateLocation(body: any) {
    return this.api.put(this.api_prefix + '/location/' + body.id, body);
  }

  addDepartment(body: any) {
    return this.api.post(this.api_prefix + '/department', body);
  }

  getDepartments(companyId: Number) {
    return this.api.get(this.api_prefix + '/department/' + companyId);
  }

  updateDepartment(body: any, dept_id: any) {
    return this.api.put(this.api_prefix + '/department/' + dept_id, body);
  }

  removeLocationFromDepartMent(body: any) {
    return this.api.put(this.api_prefix + '/department/location', body);
  }

  addLocationsToDepartments(body: any) {
    return this.api.put(this.api_prefix + '/department/location/add', body);
  }

  addScope(body: any) {
    return this.api.post('/admin/scope', body);
  }

  getAdminScope(params: any) {
    return this.api.get('/admin/scope', params);
  }

  getScope(params: any) {
    return this.api.get('/company/scope', params);
  }

  uploadScan(body: any) {
    return this.api.post('/admin/scan', body);
  }

  getScan(params: any) {
    return this.api.get('/company/scan', params);
  }

  getReports(companyId: any, params: any) {
    return this.api.get('/company/report/published/' + companyId, params);
  }

  createScan(params: any) {
    return this.api.post('/admin/published_scan', params);
  }

  createBlankScan(params: any) {
    return this.api.post('/admin/create/blank-scan', params);
  }

  getHosts(scan_id: any) {
    return this.api.get('/company/scan/' + scan_id + '/host');
  }

  getReportHosts(scan_id: any) {
    return this.api.get('/company/report/' + scan_id + '/host');
  }

  getVulnerabilities(scan_id: any) {
    return this.api.get('/company/scan/' + scan_id + '/vulnerability');
  }

  getReportVulnerabilities(scan_id: any) {
    return this.api.get('/company/report/' + scan_id + '/vulnerability');
  }

  getChartReport(scan_id: any) {
    return this.api.get('/company/scan/' + scan_id + '/vulnerability/report');
  }

  getChartReportByReportId(scan_id: any) {
    return this.api.get('/company/report/' + scan_id + '/vulnerability/report');
  }

  getVulnerabilitiesByHost(params: any) {
    return this.api.get('/company/scan/host/vulnerability', params);
  }

  getVulnerabilitiesChartReportByHost(params: any) {
    return this.api.get('/company/scan/host/vulnerability/report', params);
  }

  getVulnerabilityDetails(vulnerabilityId: any) {
    return this.api.get('/company/vulnerability/' + vulnerabilityId);
  }

  getVulnerabilitiesDashboard(params: any) {
    return this.api.get('/company/dashboard/vulnerabilities', params);
  }

  getStatisticsDashboard(params: any) {
    return this.api.get('/company/dashboard/statistics', params);
  }

  getHostsDashboard(params: any) {
    return this.api.get('/company/dashboard/hosts', params);
  }

  downloadReport(scanId: any) {
    return this.api.get('/company/report/' + scanId);
  }

  getHistoricalGraphData(params: any) {
    return this.api.get('/company/dashboard/historical', params);
  }

  getComparisonGraphData(params: any) {
    return this.api.get('/company/dashboard/comparison', params);
  }

  updateScanName(params: any, publicScanId: any) {
    return this.api.put('/admin/published_scan/' + publicScanId + '/name', params);
  }

  deletePublishedScan(publicScanId: any) {
    return this.api.delete('/admin/published_scan/' + publicScanId);
  }

  deleteScan(publicScanId: any) {
    return this.api.delete('/admin/scan/' + publicScanId);
  }

  detachUserLocation(params: any) {
    return this.api.put(this.api_prefix + '/user/detach_location', params);
  }

  getHostHistoricalData(params: any) {
    return this.api.get('/company/dashboard/hosts_historical', params);
  }

  getVulsHistoricalData(params: any) {
    return this.api.get('/company/dashboard/vulnerability_historical', params);
  }

  getAssignUsers() {
    return this.api.get('/company/jira/assign_users');
  }

  createIssue(data: any) {
    return this.api.post('/company/jira/issue', data);
  }

  getIssueStatus(id: any) {
    return this.api.get('/company/jira/issue/' + id);
  }

  deleteDepartment(id: any) {
    return this.api.delete(this.api_prefix + '/department/' + id);
  }

  saveJiraDetails(params: any) {
    return this.api.post('/company/jira/details', params);
  }

  getStatistics(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' };
    return this.api.get('/company/new_dashboard/statistics', params);
  }

  getLiveDevices(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' };
    return this.api.get('/company/new_dashboard/live_devices', params);
  }

  getVulnerabilityRating(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' };
    return this.api.get('/company/new_dashboard/vulnerability_rating', params);
  }

  getExploitableVulnerability(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' };
    return this.api.get('/company/new_dashboard/exploitable_vulnerability', params);
  }

  getVulnerabilityAge(published_scan_id: any) {
    return this.api.get('/company/new_dashboard/vulnerability_age?published_scan_id=' + published_scan_id);
  }

  getScanByYearAndMonth(year: any, month: any) {
    return this.api.get('/company/new_dashboard/published_scans?year=' + year + '&month_name=' + month);
  }

  getPublishedScanYears() {
    return this.api.get('/company/new_dashboard/published_scan_years');
  }

  getCount(id: any) {
    return this.api.get('/company/published_scan/host_vuls_count/' + id);
  }

  getCompanyPublishedScan() {
    return this.api.get('/company/published_scan');
  }

  getCompanyScanDetails(data: any) {
    return this.api.post('/company/view_scan_details', data);
  }

  getExploitableVulnerabilityList(risk: any, criteria: any, startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '', risk: risk, criteria: criteria }
    return this.api.get('/company/new_dashboard/exploitable_vulnerability_list', params);
  }

  getTopOperatingSystems(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' }
    return this.api.get('/company/top_operating_systems', params);
  }

  getTopServices(startDate?: any, endDate?: any) {
    var params = { from_date: startDate ? startDate : '', to_date: endDate ? endDate : '' }
    return this.api.get('/company/top_services', params);
  }

  bulkDeleteScans(params: any) {
    return this.api.post('/admin/scan/bulk-delete', params);
  }
}
