<div class="search-box-wrapper"
     [ngClass]="{'search-box-active': boxActive}">
    <div class="mv-sm mh input-wrapper">
        <div class="input-group">
            <span class="search-box-addon cp">
                <i @fadeIn class="fa fa-search"
                   (click)="searchInput.focus()"
                   *ngIf="!boxActive"></i>
                <i @rotateBackArrow
                   (click)="clear()"
                   class="fa fa-arrow-left"
                   *ngIf="boxActive"></i>
            </span>
            <input
                    #searchInput
                    [(ngModel)]="searchTextValue"
                    (keyup)="outText()"
                    (focus)="toggleFocus()"
                    (blur)="toggleFocus()"
                    class="b0 form-control rounded-0 border-0"
                    [placeholder]="placeHolder"/>

            <span *ngIf="searchText.length > 0"
                  @fadeIn
                  class="search-box-addon custom-position text-muted">
                <i (click)="clear()" class="fa fa-times cp"></i>
            </span>

        </div>
    </div>
</div>
