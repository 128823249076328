import { IntegrationsModule } from './routes/admin-modules/integrations/integrations.module';
import { CompanyLoginComponent } from './routes/company-components/company-login/company-login.component';
import { CompanyForgotPasswordComponent } from './routes/company-components/forgot-password/forgot-password.component';
import { ForgotPasswordComponent } from './routes/admin-components/forgot-password/forgot-password.component';
import { LoginComponent } from './routes/admin-components/login/login.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { TabsetConfig } from 'ngx-bootstrap/tabs';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { LayoutComponent } from './layout/layout.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PageNotFoundComponent } from './routes/admin-components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', title: 'Scanview' },
  { path: 'login', component: LoginComponent, title: 'Scanview' },
  { path: 'company-forgot-password', component: CompanyForgotPasswordComponent },
  { path: 'company/login', component: CompanyLoginComponent, title: 'Scanview' },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'page-not-found', component: PageNotFoundComponent, data: { title: 'Page Not Found' } },
  { path: 'account', loadChildren: () => import('../app/routes/admin-modules/account/account.module').then((m) => m.AccountModule) },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('../app/routes/admin-modules/dashboard/dashboard.module').then((m) => m.DashboardModule) , title: 'Dashboard' },
      { path: 'access-logs', loadChildren: () => import('../app/routes/admin-modules/access-logs/access-logs.module').then((m) => m.AccessLogsModule) },
      { path: 'calendar', loadChildren: () => import('../app/routes/admin-modules/calendar/calendar.module').then((m) => m.CalendarModule) },
      { path: 'users', loadChildren: () => import('../app/routes/admin-modules/user/user.module').then((m) => m.UserModule) },
      { path: 'partners-management', loadChildren: () => import('../app/routes/admin-modules/partners-management/partners-management.module').then((m) => m.PartnersManagementModule) },
      { path: 'companies', loadChildren: () => import('../app/routes/admin-modules/company/company.module').then((m) => m.CompanyModule) },
      { path: 'company-users', loadChildren: () => import('../app/routes/admin-modules/company-user/company-user.module').then((m) => m.CompanyUserModule) },
      { path: 'graph-visualization', loadChildren: () => import('../app/routes/admin-modules/graph-visualization/graph-visualization.module').then((m) => m.GraphVisualizationModule) },
      { path: 'schedule-scan', loadChildren: () => import('../app/routes/admin-modules/schedule-scan/schedule-scan.module').then((m) => m.ScheduleScanModule) },
      { path: 'master-plugin', loadChildren: () => import('../app/routes/admin-modules/master-plugin/master-plugin.module').then((m) => m.MasterPluginModule) },
      { path: 'published-scan/upload', loadChildren: () => import('../app/routes/admin-modules/published-scan/upload/upload.module').then((m) => m.UploadModule) },
      { path: 'published-scan/list', loadChildren: () => import('../app/routes/admin-modules/published-scan/published-scans-list/published-scans-list.module').then((m) => m.PublishedScansListModule) },
      { path: 'published-scan/scan-list/:companyId', loadChildren: () => import('../app/routes/admin-modules/published-scan/scans-listing/scans-listing.module').then((m) => m.ScansListingModule) },
      { path: 'blank-scan/:publishedScanId', loadChildren: () => import('../app/routes/admin-modules/published-scan/blank-scan/blank-scan.module').then((m) => m.BlankScanModule) },
      { path: 'published-scan/select-plugins/:publishedScanId', loadChildren: () => import('../app/routes/admin-modules/published-scan/plugin-selection/plugin-selection.module').then((m) => m.PluginSelectionModule) },
      { path: 'published-scan/modify-published-scan', loadChildren: () => import('../app/routes/admin-modules/published-scan/modify-published-scan/modify-published-scan.module').then((m) => m.ModifyPublishedScanModule) },
      { path: 'published-scan/search', loadChildren: () => import('../app/routes/admin-modules/published-scan/search-published-scans/search-published-scans.module').then((m) => m.SearchPublishedScansModule) },
      { path: 'published-scan/vulnerability-override-data', loadChildren: () => import('../app/routes/admin-modules/published-scan/vulnerability-override-data/vulnerability-override-data.module').then((m) => m.VulnerabilityOverrideDataModule) },
      { path: 'published-scan/vulnerability-exclude', loadChildren: () => import('../app/routes/admin-modules/published-scan/vulnerability-exclude-data/vulnerability-exclude-data.module').then((m) => m.VulnerabilityExcludeDataModule) },
      { path: 'published-scan/manage-exceptions', loadChildren: () => import('../app/routes/admin-modules/published-scan/manage-exceptions/manage-exceptions.module').then((m) => m.ManageExceptionsModule) },
      { path: 'published-scan/mappings', loadChildren: () => import('../app/routes/admin-modules/published-scan/mappings/mappings.module').then((m) => m.MappingsModule) },
      { path: 'published-scan/scan', loadChildren: () => import('../app/routes/admin-modules/published-scan/scan/scan.module').then((m) => m.ScanModule) },
      { path: 'reports', loadChildren: () => import('../app/routes/admin-modules/reports/reports.module').then((m) => m.ReportsModule) },
      { path: 'report-pages', loadChildren: () => import('../app/routes/admin-modules/report-page-builder/report-page-builder.module').then((m) => m.ReportPageBuilderModule) },
      { path: 'report-editable-contents', loadChildren: () => import('../app/routes/admin-modules/report-editable-content/report-editable-content.module').then((m) => m.ReportEditableContentModule) },
      { path: 'settings', loadChildren: () => import('../app/routes/admin-modules/settings/settings.module').then((m) => m.SettingsModule) },
      { path: 'integrations', loadChildren: () => import('../app/routes/admin-modules/integrations/integrations.module').then((m) => m.IntegrationsModule) },
      { path: 'notifications', loadChildren: () => import('../app/routes/admin-modules/notifications/notifications.module').then((m) => m.NotificationsModule) }
    ]
  },
  {
    path: 'company_auth',
    loadChildren: () =>
      import('../app/routes/company-components/company-components.module').then((m) => m.CompanyComponentsModule),
  },
  {
    path: 'company',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('../app/routes/company-modules/company-dashboard/company-dashboard.module').then((m) => m.CompanyDashboardModule), title: 'Dashboard' },
      { path: 'settings', loadChildren: () => import('../app/routes/company-modules/company-settings/company-settings.module').then((m) => m.CompanySettingsModule), title: 'Settings' },
      { path: 'scans', loadChildren: () => import('../app/routes/company-modules/scan-reports/scan-reports.module').then((m) => m.ScanReportsModule), title: 'Scan List' },
      { path: 'users', loadChildren: () => import('../app/routes/company-modules/company-user/company-user.module').then((m) => m.CompanyUserModule) },
      { path: 'locations', loadChildren: () => import('../app/routes/company-modules/company-location-departments/company-location-departments.module').then((m) => m.CompanyLocationDepartmentsModule) },
      { path: 'integrations', loadChildren: () => import('../app/routes/company-modules/integrations/integrations.module').then((m) => m.IntegrationsModule) },
    ]
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes), FormsModule, ReactiveFormsModule, CommonModule, NgSelectModule, NgMultiSelectDropDownModule],
  exports: [RouterModule, SharedModule, FormsModule, ReactiveFormsModule],
  providers: [TabsetConfig,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule { }
